import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import NotLoggedModal from 'components/NotLogged';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import Card from '../../components/Card/Card';
import MapOrSchoolToggleMin from '../../components/MapOrSchoolToggle/MapOrSchoolToggleMin';
import MapViewMin from '../../components/MapView/MapViewMin';
import Footer from '../../components/Footer';
import Spinner from '../../components/Spinner';
import VacantestBanner from '../../components/VacantestBanner';
import featureService from '../../services/featureService';
import { calculateMapCenter } from '../../utils/Math/coords';

import './styles.css';

const SchoolsByLocation = () => {
  const { city: cityName, neighborhood: neighborhoodName } = useParams();
  const [show, toggleShow] = useState(true);
  const [neighborhood, setNeighborhood] = useState();
  const [schools, setSchools] = useState([]);
  const [experiencesEnabled, setExperiencesEnabled] = useState(false);

  useEffect(() => {
    api.get(`/school/searchneighborhood/${cityName}/${neighborhoodName}`)
      .then((response) => {
        if (response.data.schools) {
          setSchools(response.data.schools);
          setNeighborhood(response.data.neighborhood);
        }
      })
      .catch((_err) => {
        // console.log('ERR', err);
      });
    featureService.experiencesEnabled().then(setExperiencesEnabled);
  }, [neighborhoodName]);

  const renderNoSchoolsMessage = () => (
    <div className="shadow empty-search-container">
      <p>No se han encontrado centros educativos en este barrio.</p>
    </div>
  );

  const renderCardList = () => {
    if (!neighborhood) return renderNoSchoolsMessage();
    if (!schools?.length) return renderNoSchoolsMessage();

    return schools
      .map((school) => (
        <Card
          key={school.id}
          school={school}
          minimalistic
          // contactSchool={contactSchool}
        />
      ));
  };

  const renderMap = () => {
    if (!neighborhood) return renderNoSchoolsMessage();
    if (!schools?.length) return renderNoSchoolsMessage();

    const neighborhoodHasCoords = neighborhood.latitude && neighborhood.longitude;
    const center = !neighborhoodHasCoords && calculateMapCenter(schools);

    return (
      <MapViewMin
        latitude={neighborhoodHasCoords ? neighborhood.latitude : center.latitude}
        longitude={neighborhoodHasCoords ? neighborhood.longitude : center.longitude}
        heightSize="80vh"
        widthSize="1115px"
        schools={schools}
      />
    );
  };

  return (
    <div className="main-wrapper container-xl">
      <Header />
      <Helmet>
        {neighborhood ? <title>{`Centros educativos en ${neighborhood.name} - VacantED`}</title> : null}
        {neighborhood ? <meta name="description" content={`Jardines, colegios, escuelas y liceos de ${neighborhood.name}`} /> : null}
      </Helmet>
      {!neighborhood ? <Spinner props={{ schoolsLoadingState: true, size: 50 }} /> : (
        <section className="body-wrapper row">
          <VacantestBanner experiencesEnabled={experiencesEnabled} />
          <h1 className="page-title">
            Centros educativos en
            {' '}
            {`${neighborhood.name || ''}`}
          </h1>
          <h2 className="page-subtitle">
            Se incluyen los jardines, escuelas, liceos y colegios de
            {' '}
            {`${neighborhood.name || ''}`}
          </h2>
          <main className="body-content col">
            <MapOrSchoolToggleMin toggle={toggleShow} loading={false} schools={schools} />
            <div className="card-map-container">
              {neighborhood && show && renderCardList()}
              {neighborhood && !show && renderMap()}
            </div>
          </main>
        </section>
      )}
      <div className="footer-container">
        <Footer />
      </div>
      {/* <NotLoggedModal Property="Colegios" Action="Contactar o guardar" /> */}
      <NotLoggedModal Property="Colegios" Action="Guardar" />
    </div>
  );
};

export default SchoolsByLocation;
