import React, { useState } from 'react';
// import Services from 'services/Services';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactStars from 'react-rating-stars-component';
import { useDispatch } from 'react-redux';

// style
import './styles.css';

// assets
import { FaMapMarkerAlt } from 'react-icons/fa';
/* import FavoriteHeart from 'components/Favorite'; */
import { fetchSchoolById } from '../../hooks/SchoolProfileContext/SchoolProfileSlice';
import { useSearch } from '../../hooks/SearchContext';
import { getAgesMessageToShow } from '../../utils/schools/schoolFunctions';
import AddRemoveCandidateButton from '../../pages/Experience/src/components/buttons/add-remove-candidate';
import RequestVisitButton from '../../pages/Experience/src/components/buttons/request-visit';

// https://codesandbox.io/s/oo1y2y1o59?file=/src/index.js:61-116 REVIEW QUE USEI
import ContactModal from '../ContactModal/ContactModal';
import IconsWrapper from './IconsWrapper';

import cardStyles from './cardStyles.module.css';

function getTypeOfLanguage(primary, secondaryLanguage, tertiaryLanguage) {
  if (!secondaryLanguage && !tertiaryLanguage && primary) return primary;
  if (secondaryLanguage && !tertiaryLanguage) return 'Bilingüe';
  if (tertiaryLanguage) return 'Trilingüe';
  return '';
}

const Card = ({ school, minimalistic }) => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(false);
  // const [statusFavorite, setStatusFavorite] = useState(false);
  // const [schoolCalendar, setSchoolCalendar] = useState([]);

  const { locationInfo } = useSearch();
  // const windowSize = useWindowSize();

  const dispatch = useDispatch();

  const schoolContactDisabled = school.contact_disabled;
  const schoolIsPublic = school.public_id === 2 || school.public_id === 7;
  const contactDisabled = schoolIsPublic || schoolContactDisabled;

  async function handleOpenContactModal(e, id, action) {
    setModalAction(action);
    // const response = await Services.getSchoolCalendar(id);
    // setSchoolCalendar(response.data);
    dispatch(fetchSchoolById(id));
    setIsContactModalOpen(true);
    // contactSchool(id);
  }
  function handleCloseContactModal() {
    setIsContactModalOpen(false);
  }

  function showContactButton() {
    return (
      <div className={`d-flex align-items-center ${cardStyles.margin__sides}`}>
        <button
          type="button"
          className={contactDisabled ? 'card-button-disabled mt-3 school__card__contact__button__disabled' : 'card-button mt-3 school__card__contact__button'}
          onClick={(e) => handleOpenContactModal(e, school.id)}
          disabled={contactDisabled}
        >
          Contactar
        </button>
      </div>
    );
  }

  function showInterviewButton() {
    return (
      <div className={`d-flex align-items-center ${cardStyles.margin__sides}`}>
        <button
          type="button"
          className={contactDisabled ? 'card-button-disabled mt-3 school__card__contact__button__disabled' : 'card-button mt-3 school__card__contact__button'}
          onClick={(e) => handleOpenContactModal(e, school.id, 'visit')}
          disabled={contactDisabled}
        >
          Solicitar entrevista
        </button>
      </div>
    );
  }

  function getReviewsMsg(reviews) {
    return (reviews.length !== 1) ? 'reseñas' : 'reseña';
  }

  return (
    <div className={cardStyles.div__card__link}>
      <div className={`${cardStyles['blockCard-container']} row w-100 p-2 pb-3`}>
        <div className="Logo col-sm-12 d-flex col-lg-4 align-self-center justify-content-center">
          <Link
            to={`/school/${school.id}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={school.logo || 'https://images.vacanted.com.uy/logos/vacanted_logo_min.png'} className="card-img" alt="logo" />
          </Link>
        </div>
        <div className="col-sm-12 col-lg-8 flex-fill">
          <div className="w-100">
            <div>
              <h5 className="d-flex mb-0 justify-content-between pt-2">
                <a
                  className={cardStyles.card__school__title}
                  href={`/school/${school.id}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {school.name}
                </a>
                <div />
                {' '}
                {/* {!minimalistic && <FavoriteHeart school={school} from="advancedSearch" />} */}
              </h5>
            </div>
            <div className="school__card__address__row">
              <div className="school__card__address theme-font d-flex mb-0 col-12 col-lg-8 align-items-center">
                <span className="theme-font-sm address__row">
                  <FaMapMarkerAlt size={17} color="#36a7eb" />
                </span>
                <p
                  className="theme-font-sm sl font-weight-lightBold mb-0 mt-1 pr-1 address__row"
                  data-toogle="tooltip"
                  data-placement="top"
                  title={`${school.address}\n${school.city.name}`}
                >
                  <span className="card__address">
                    {`${school.address}, ${school.neighborhood.name}, ${school.city.name}`}

                  </span>

                </p>
              </div>
              {!minimalistic && (
                <p className="card__distance__icon theme-font justify-content-start align-items-end d-flex col-5 col-lg-4">
                  <span>
                    <FaMapMarkerAlt size={17} color="#36a7eb" />
                    <FaMapMarkerAlt color="#36a7eb" />
                    <span className="distance__span">
                      {`${school.distance} km`}
                    </span>
                  </span>
                </p>
              )}
            </div>
            <div>
              <p className={`${cardStyles.card__row} theme-font`}>
                {`${school.public.name} de Educación ${getAgesMessageToShow(school.schedules, locationInfo.countryId)}`}
              </p>
            </div>
            <IconsWrapper
              price={school.cost.name}
              language={getTypeOfLanguage(school.language1?.name,
                school.language2?.name, school.language3?.name)}
              religious={school.religious}
            />
            <div className="d-flex justify-content-between align-items-center card__last__row">
              <div className="d-flex align-items-center reviews" title="Fuente: Google™">
                <ReactStars
                  size={20}
                  half
                  edit={false}
                  value={Number(school.rating)}
                />
                <span className="font-weight-bold pl-1 theme-font">
                  {`(${school.user_ratings_total} ${getReviewsMsg(school.user_ratings_total)})`}
                </span>
              </div>
              {/* <span>{school.reviews}</span> */}
              <div className="d-flex actions">
                {false && showContactButton()}
                {false && showInterviewButton()}
                <RequestVisitButton
                  schoolId={school.id}
                  disabled={school.contact_disabled || school.public?.is_public}
                />
                <AddRemoveCandidateButton
                  schoolId={school.id}
                />
              </div>
              {isContactModalOpen && (
              <ContactModal
                isOpen={isContactModalOpen}
                onRequestClose={() => handleCloseContactModal()}
                schoolName={school.name}
                schoolId={school.id}
                modalAction={modalAction}
              />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  school: PropTypes.shape({
    id: PropTypes.number,
    logo: PropTypes.string,
    distance: PropTypes.number,
    name: PropTypes.string,
    public_id: PropTypes.number,
    link: PropTypes.string,
    address: PropTypes.string,
    neighborhood: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    city: PropTypes.shape({
      name: PropTypes.string,
    }),
    public: PropTypes.shape({
      name: PropTypes.string,
      is_public: PropTypes.bool,
    }),
    initial: PropTypes.bool,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    cost: PropTypes.shape({
      name: PropTypes.string,
    }),
    price: PropTypes.string,
    schedules: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    language1: PropTypes.shape({
      name: PropTypes.string,
    }),
    language2: PropTypes.shape({
      name: PropTypes.string,
    }),
    language3: PropTypes.shape({
      name: PropTypes.string,
    }),
    religious: PropTypes.bool,
    religion: PropTypes.shape({
      name: PropTypes.string,
    }),
    rating: PropTypes.string,
    user_ratings_total: PropTypes.number,
    reviews: PropTypes.string,
    contact_disabled: PropTypes.bool,
  }).isRequired,
  minimalistic: PropTypes.bool,
  // contactSchool: PropTypes.func.isRequired,
};

Card.defaultProps = {
  minimalistic: false,
};

export default Card;
