/* eslint-disable import/prefer-default-export */

export function calculateMapCenter(schools) {
  if (!schools || schools.length === 0) {
    throw new Error('La lista de coordenadas no puede estar vacía.');
  }

  let x = 0;
  let y = 0;
  let z = 0;

  schools.forEach(({ latitude, longitude }) => {
    if (latitude === undefined || longitude === undefined) {
      throw new Error('Cada coordenada debe tener propiedades lat y lng.');
    }

    // Convertir latitud y longitud a radianes
    const latitudeRad = (latitude * Math.PI) / 180;
    const longitudeRad = (longitude * Math.PI) / 180;

    // Convertir a coordenadas cartesianas
    x += Math.cos(latitudeRad) * Math.cos(longitudeRad);
    y += Math.cos(latitudeRad) * Math.sin(longitudeRad);
    z += Math.sin(latitudeRad);
  });

  const totalPoints = schools.length;

  // Promedio de las coordenadas cartesianas
  x /= totalPoints;
  y /= totalPoints;
  z /= totalPoints;

  // Convertir de vuelta a coordenadas esféricas
  const centralLongitude = Math.atan2(y, x);
  const hypotenuse = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, hypotenuse);

  // Convertir de radianes a grados
  return {
    latitude: (centralLatitude * 180) / Math.PI,
    longitude: (centralLongitude * 180) / Math.PI,
  };
}
