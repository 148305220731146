export const schoolProfileConstants = {
  postulation: {
    es: 'Postulación',
    pt: 'Postulação',
  },
};

const asideSessions = [
  {
    id: 0, name: 'Información básica', ref: null, active: false,
  },
  {
    id: 1, name: 'Calendario de Visitas Guiadas', ref: null, active: false,
  },
  {
    id: 2, name: 'Características Principales', ref: null, active: false,
  },
  {
    id: 3, name: 'Propuesta educativa', ref: null, active: false,
  },
  {
    id: 4, name: 'Idiomas', ref: null, active: true,
  },
  {
    id: 5, name: 'Religión', ref: null, active: false,
  },
  {
    id: 6, name: 'Actividades Extracurriculares', ref: null, active: false,
  },
  {
    id: 7, name: 'Adaptación Curricular', ref: null, active: false,
  },
  {
    id: 8, name: 'Servicios Incluidos', ref: null, active: false,
  },
  {
    id: 9, name: 'Instalaciones', ref: null, active: false,
  },
  {
    id: 10, name: 'Premios y Reconocimientos', ref: null, active: false,
  },
  {
    id: 11, name: 'Eventos Institucionales', ref: null, active: false,
  },
  {
    id: 12, name: 'Medidas de Seguridad', ref: null, active: false,
  },
  {
    id: 13, name: 'Ubicación', ref: null, active: false,
  },
  {
    id: 14, name: 'Reseñas', ref: null, active: false,
  },
];

export default asideSessions;
